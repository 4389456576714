export const categoryList = [
	{ code: '5399', name: 'Miscellaneous General Merchandise Stores' },
	{ code: '4900', name: 'Utilities' },
	{ code: '4722', name: 'Travel/Hospitality' },
	{ code: '8211', name: 'Education - Elementary & Secondary Schools' },
	{
		code: '8220',
		name: 'Education - Colleges, Universities, Protessional Schools, and Junior Colleges',
	},
	{ code: '8299', name: 'Other Education Services' },
	{ code: '7995', name: 'Gaming & Lottery' },
	{ code: '7995', name: 'Betting - Sports' },
	{ code: '7994', name: 'Video games' },
	{ code: '0763', name: 'Agriculture' },
	{ code: '5815', name: 'Digital goods' },
	{ code: '7832', name: 'Entertainment' },
	{ code: '8661', name: 'Religious Organizations' },
	{ code: '5960', name: 'Insurance' },
	{ code: '4511', name: 'Travel/Airlines' },
	{ code: '8398', name: 'Charity/NGOs' },
	{ code: '4121', name: 'Taxicabs' },
	{ code: '8999', name: 'Professional Services' },
	{ code: '6513', name: 'Real Estate' },
	{ code: '6012', name: 'Financial Institutions' },
	{ code: '6051', name: 'Non Financials/Cryptocurrency' },
	{ code: '8931', name: 'Tax, Accounting, Auditing' },
	{ code: '4829', name: 'Monev transfer' },
	{ code: '4899', name: 'Cable, Satellite & other TV' },
	{ code: '5399', name: 'Others' },
];
